import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/GlobalProvider";
import { Admin_CRUD_TRANSACTIONS_URL } from "../hooks/APIHooks";
import { Link } from "react-router-dom";
import { RiArrowGoBackFill, RiStore3Fill } from "react-icons/ri";

const AllGoldSmithHistory = () => {
  const { user } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState({
    PROCESSING: true,
    Delivered: false,
    Cancelled: false,
  });

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(Admin_CRUD_TRANSACTIONS_URL);
        const data = await response.json();

        if (data.success) {
          setTransactions(data.transactions);
        } else {
          setError("Failed to fetch transactions");
        }
      } catch (error) {
        setError("Error fetching transactions");
      }
      setLoading(false);
    };

    fetchTransactions();
  }, []);

  const handleChange = async (id, newDeliveryStatus) => {
    const confirmed = window.confirm(
      "Are you sure you want to change the delivery status?"
    );
    if (!confirmed) return;

    const updatedTransactions = transactions.map((transaction) =>
      transaction.id === id
        ? { ...transaction, delivery: newDeliveryStatus }
        : transaction
    );
    setTransactions(updatedTransactions);

    const transaction = updatedTransactions.find((t) => t.id === id);

    try {
      const response = await fetch(`${Admin_CRUD_TRANSACTIONS_URL}/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(transaction),
      });

      const data = await response.json();

      if (!data.success) {
        alert("Failed to update transaction");
      }
    } catch (error) {
      alert("Error updating transaction");
    }
  };

  const handleFilterChange = (status) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [status]: !prevFilter[status],
    }));
  };

  const renderTable = (transactions) => (
    <div className="bg-white shadow-lg rounded-md p-6 mb-6">
      <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">
        GoldSmith History
      </h2>

      {transactions.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <tbody>
              {transactions
                .filter(
                  (transaction) =>
                    transaction.shortName === "BAT_GoldSmith" &&
                    filter[transaction.delivery]
                )
                .map((transaction) => (
                  <tr key={transaction.id} className="border-b">
                    <td className="grid grid-cols-2 gap-4 p-4 text-black">
                      <div className="text-[14px]">
                        Paid: <br />
                        {transaction.amount}
                      </div>
                      <div className="text-[12px]">
                        Received Gold: <br />
                        {transaction.orderlist}
                      </div>
                      <div className="text-[10px] font-bold">
                        {" "}
                        Transation ID: <br />
                        {transaction.merchantTransactionId}
                        <br />
                        {transaction.order_date}
                      </div>
                      <div className="text-[10px]">
                        {transaction.email}
                        <br />
                        {transaction.mobileNumber}
                      </div>
                    </td>

                    <div className="grid grid-cols-2">
                      <td className="py-2 px-1 text-[10px] text-gray-700">
                        <td className="py-2 px-1 text-[10px] text-gray-700">
                          <select
                            value={transaction.delivery}
                            onChange={(e) =>
                              handleChange(transaction.id, e.target.value)
                            }
                          >
                            <option value="PROCESSING">PROCESSING</option>
                            <option value="Delivered">Delivered</option>
                            <option value="Cancelled">Cancelled</option>
                          </select>
                        </td>
                        <div>
                          <a
                            href={`/invoice?transactionId=${transaction.transactionId}&message=${transaction.message}&quantity=${transaction.quantity}&amount=${transaction.amount}&email=${transaction.email}&mobileNumber=${transaction.mobileNumber}&orderlist=${transaction.orderlist}&createdAt=${transaction.created_at}`}
                            className="text-blue-500 hover:text-blue-700"
                          >
                            View More
                          </a>
                        </div>
                      </td>
                    </div>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-600">
          No goldsmith transactions found.
        </p>
      )}
    </div>
  );

  return (
    <section className="p-2 bg-gray-100 min-h-screen py-20">
      <div className="mb-4">
        <label className="mr-2">Filter by Delivery:</label>
        <div className="flex items-center space-x-4">
          <label>
            <input
              type="checkbox"
              checked={filter.PROCESSING}
              onChange={() => handleFilterChange("PROCESSING")}
            />
            <span className="ml-1">PROCESSING</span>
          </label>
          <label>
            <input
              type="checkbox"
              checked={filter.Delivered}
              onChange={() => handleFilterChange("Delivered")}
            />
            <span className="ml-1">Delivered</span>
          </label>
          <label>
            <input
              type="checkbox"
              checked={filter.Cancelled}
              onChange={() => handleFilterChange("Cancelled")}
            />
            <span className="ml-1">Cancelled</span>
          </label>
        </div>
      </div>
      {loading ? (
        <div className="flex items-center justify-center h-screen text-red-500">
          Loading...
        </div>
      ) : error ? (
        <div className="flex items-center justify-center h-screen text-red-500">
          {error}
        </div>
      ) : (
        renderTable(transactions)
      )}
      {/* Navigation Links */}
      <div className="flex justify-center fixed bottom-0 w-full p-2 z-10">
        <div className="flex flex-row justify-around w-full max-w-md p-2 bg-yellow-500 mx-2 rounded-full">
          <div className="flex flex-col items-center group">
            <Link
              to="/user/dashboard"
              className="flex flex-col items-center px-4 rounded-full text-white"
            >
              <RiArrowGoBackFill className="h-6 w-6 mb-1 text-white" />
              <span className="text-[10px]">BACK</span>
            </Link>
          </div>
          <div className="flex flex-col items-center group">
            <Link
              to="/"
              className="flex flex-col items-center px-4 rounded-full text-white"
            >
              <RiStore3Fill className="h-6 w-6 mb-1 text-white" />
              <span className="text-[10px]">HOME</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllGoldSmithHistory;
