import React from 'react';

const Footer = () => {
    return (
        <footer className="text-[10px]  bg-black text-center text-white bottom-0 ">
            <p>&copy; {new Date().getFullYear()} CyberSpaceDigital. All rights reserved.</p>
        </footer>
    );
};

export default Footer;
