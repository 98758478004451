import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/GlobalProvider";
import { IoPeopleSharp, IoReceiptSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { FaHammer } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { IoMdLogOut } from "react-icons/io";
import { CRUDUsers } from "../hooks/APIHooks";
import { FcFilledFilter,FcBarChart,FcTreeStructure,FcShop,FcBusinesswoman,FcButtingIn,FcPicture,FcSettings } from "react-icons/fc";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [totalUsers, setTotalUsers] = useState(0);


  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userData");
    navigate("/");
  };

  useEffect(() => {
    if (user && user.role === "admin") {
      const fetchUserCount = async () => {
        try {
          const response = await fetch(CRUDUsers, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ role: user.role }),
          });

          const data = await response.json();
          if (data.success) {
            setTotalUsers(data.users.length);
          } else {
            console.error("Failed to fetch user count");
          }
        } catch (error) {
          console.error("Error fetching user count", error);
        }
      };

      fetchUserCount();
    }
  }, [user]);


  return (
    <section>
      <div className="p-4 mb-6 overflow-x-hidden">
        <div>
          <div className="mb-8">
            <h1 className="text-4xl text-gray-800 font-bold">Dashboard</h1>
            <p className="text-sm text-gray-600 mt-2 mb-4 flex items-center">
              Welcome, {user ? user.name : "User"} / {user?.role || "Role Unknown"}! 
              Please select your service. For more information, contact the developer for further assistance.
            </p>
          </div>

          <div className="grid grid-cols-2 gap-2">
            <div className="grid grid-row-1 shadow-md rounded-md">
              <div className="bg-violet-100 flex flex-col p-2">
              {totalUsers}
                <h2 className="text-[12px] font-semibold flex">
                  <IoPeopleSharp className="text-blue-500 text-[16px] mr-1" />
                  Total Users
                </h2>
                <p className="text-[10px] text-gray-600 flex">
                  Users Entered in this dashboard.
                </p>
              </div>
            </div>

            
          </div>
        </div>

        <div className=" bg-white text-center justify-center items-center ">
          <h2 className="text-[14px] font-bold my-3">Quick Create</h2>{" "}
          <div className="grid grid-cols-4 gap-4">
          <div>
              <Link
                to="/users"
                className="flex flex-col items-center justify-center bg-yellow-500 text-white p-4 rounded-lg shadow-lg hover:bg-yellow-600 hover:text-white transition duration-300"
              >
                <IoIosPeople className="text-3xl  " />
              </Link>
              <h3 className="text-[14px]  font-bold mt-2">Users</h3>
              <p className="text-[8px] text-center">
                Add or Remov User.
              </p>
            </div>

           

           

            <div>
              <Link
                to="/Productsadd"
                className="flex flex-col items-center justify-center bg-teal-500 text-white p-4 rounded-lg shadow-lg hover:bg-teal-600 hover:text-white transition duration-300"
              >
                <FcFilledFilter className="text-3xl  " />
              </Link>
              <h3 className="text-[14px]  font-bold mt-2">Products</h3>
              <p className="text-[8px] text-center">
                Add & Manage your Products.
              </p>
            </div>

           
          </div>
        </div>

        <div className="my-6 ">
          {/* Header Section */}

          {/* Services Section */}
          <div className="text-center mb-6">
            <h2 className="text-[20px] font-semibold tracking-tight text-gray-800">
              Services
            </h2>
            <p className="text-gray-500 text-sm mt-1">
              All the services you need to manage your business.
            </p>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-5">
            <Link
              to="/Productsadd"
              className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
            >
              <FcFilledFilter className="text-3xl mb-2 " />
              <h3 className="text-[16px]  font-bold">Add Products</h3>
              <p className="text-[10px] text-center">
                Create & Manage your Bills
              </p>
            </Link>

            <Link
              to="/LiveGold"
              className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
            >
              <FcBarChart className="text-3xl mb-2 " />
              <h3 className="text-[16px]  font-bold">Live Price Update</h3>
              <p className="text-[10px] text-center">
                Create & Manage your Bills
              </p>
            </Link>
          </div>



            {/* Transactions Section */}
            <div className="text-center mb-6">
            <h2 className="text-[20px] font-semibold tracking-tight text-gray-800">
            Transactions
            </h2>
            <p className="text-gray-500 text-sm mt-1">
              All the services you need to manage your business.
            </p>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-5">
            <Link
              to="/AllTransationsHistory"
              className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
            >
              <FcTreeStructure className="text-3xl mb-2 " />
              <h3 className="text-[16px]  font-bold">All Transactions</h3>
              <p className="text-[10px] text-center">
                Create & Manage your Bills
              </p>
            </Link>

            <Link
              to="/AllStoreHistory"
              className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
            >
              <FcShop className="text-3xl mb-2 " />
              <h3 className="text-[16px]  font-bold">All Store History</h3>
              <p className="text-[10px] text-center">
                Create & Manage your Bills
              </p>
            </Link>

            <Link
              to="/AllReferralHistory"
              className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
            >
              <IoIosPeople className="text-3xl mb-2 " />
              <h3 className="text-[16px]  font-bold">All Rederral Store History</h3>
              <p className="text-[10px] text-center">
                Create & Manage your Bills
              </p>
            </Link>

            <Link
              to="/AllGoldSmithHistory"
              className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
            >
              <FaHammer className="text-3xl mb-2 " />
              <h3 className="text-[16px]  font-bold">GoldSmith History</h3>
              <p className="text-[10px] text-center">
                Create & Manage your Bills
              </p>
            </Link>

            <Link
              to="/AllSubscriptions"
              className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
            >
              <FcBusinesswoman className="text-3xl mb-2 " />
              <h3 className="text-[16px]  font-bold">AllSubscriptions</h3>
              <p className="text-[10px] text-center">
                Create & Manage your Bills
              </p>
            </Link>
          

            <Link
              to="/AllSubscriptionTransations"
              className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
            >
              <FcButtingIn className="text-3xl mb-2 " />
              <h3 className="text-[16px]  font-bold">AllSubscriptionTransations</h3>
              <p className="text-[10px] text-center">
                Create & Manage your Bills
              </p>
            </Link>
          


          </div>

        

      
          <div className="border-y-[0.5px] border-gray-300 border my-2" />

          {/* App Settings Section */}
          <div className="text-center mb-6">
            <h2 className="text-[20px] font-semibold tracking-tight text-gray-800">
              App Settings
            </h2>
            <p className="text-gray-500 text-sm mt-1">
              Customize your app settings.
            </p>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <Link
              to="/media"
              className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
            >
              <FcPicture className="text-3xl mb-2" />
              <h3 className="text-[16px]  font-bold">Media</h3>
              <p className="text-[10px] text-center">
                Configure your business details
              </p>
            </Link>

            <Link
              to="/settings"
              className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
            >
              <FcSettings className="text-3xl mb-2" />
              <h3 className="text-[16px]  font-bold">Settings</h3>
              <p className="text-[10px] text-center">
                Configure your business details
              </p>
            </Link>


            <button
              onClick={handleLogout}
              className="flex flex-col items-center justify-center bg-red-500 text-white p-6 rounded-lg shadow-lg hover:bg-red-600 transition duration-300"
            >
              <IoMdLogOut className="text-3xl mb-2" />
              <h3 className="text-[16px]  font-bold">Logout</h3>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
