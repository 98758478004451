import React from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Updates from "./components/Tools/Updates";
import LoginPage from "./LoginPage";
import Dashboard from "./routes/Dashboard";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import SettingsPage from "./routes/Settings/SettingsPage";
import NotFoundPage from "./components/NotFoundPage";

import Users from "./routes/Users/Users";
import UserDetails from "./routes/Users/UserDetails";

import Media from "./routes/Media/Media";
import Productpost from "./routes/ProductsPost";
import LiveGoldPriceChange from './routes/LiveGoldPriceChange'
import AllTransationsHistory from "./routes/AllTransationsHistory";
import AllGoldSmithHistory from './routes/AllGoldSmithHistory.js'
import AllStoreHistory from './routes/AllStoreHistory.js'
import AllReferralHistory from './routes/AllReferralHistory.js'
import AllSubscriptions from './routes/AllSubscriptions.js'
import AllSubscriptionTransations from './routes/AllSubscriptionTransations.js'

const App = () => {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  return (
    <>
      {isLoggedIn && <Navbar />} {/* Conditionally render Navbar */}
      <Routes location={location} key={location.pathname}>
        {/* Public routes */}
        <Route
          path="/"
          element={isLoggedIn ? <Navigate to="/dashboard" /> : <LoginPage />}
        />
        <Route
          path="/dashboard"
          element={isLoggedIn ? <Dashboard /> : <Navigate to="/" />}
        />
        <Route
          path="/users"
          element={isLoggedIn ? <Users /> : <Navigate to="/" />}
        />
        <Route
          path="/users/user-details/:userId"
          element={isLoggedIn ? <UserDetails /> : <Navigate to="/" />}
        />
        <Route
          path="/media"
          element={isLoggedIn ? <Media /> : <Navigate to="/" />}
        />
        <Route
          path="/Productsadd"
          element={isLoggedIn ? <Productpost /> : <Navigate to="/" />}
        />
         <Route
          path="/LiveGold"
          element={isLoggedIn ? <LiveGoldPriceChange /> : <Navigate to="/" />}
        />

<Route
          path="/AllTransationsHistory"
          element={isLoggedIn ? <AllTransationsHistory /> : <Navigate to="/" />}
        />


<Route
          path="/AllStoreHistory"
          element={isLoggedIn ? <AllStoreHistory /> : <Navigate to="/" />}
        />
        
<Route
          path="/AllReferralHistory"
          element={isLoggedIn ? <AllReferralHistory /> : <Navigate to="/" />}
        />

<Route
          path="/AllGoldSmithHistory"
          element={isLoggedIn ? <AllGoldSmithHistory /> : <Navigate to="/" />}
        />

<Route
          path="/AllSubscriptions"
          element={isLoggedIn ? <AllSubscriptions /> : <Navigate to="/" />}
        />

<Route
          path="/AllSubscriptionTransations"
          element={isLoggedIn ? <AllSubscriptionTransations /> : <Navigate to="/" />}
        />

        <Route
          path="/settings"
          element={isLoggedIn ? <SettingsPage /> : <Navigate to="/" />}
        />
        {/* 404 Not Found route */}
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/updates" element={<Updates />} /> {/* Public route */}
      </Routes>
      {isLoggedIn && <Footer />} {/* Conditionally render Footer */}
    </>
  );
};

export default App;
