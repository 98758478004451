// src/components/PriceTable.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { RiArrowGoBackFill, RiStore3Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import {GOLD_LIVE_PRICE} from '../hooks/APIHooks'

const PriceTable = () => {
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch prices from the API
    axios
      .get(GOLD_LIVE_PRICE)
      .then((response) => {
        setPrices(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching prices:", error);
        setLoading(false);
      });
  }, []);

  // Function to handle update
  const handleUpdate = (productName, price1, price2) => {
    axios
      .post(GOLD_LIVE_PRICE, {
        product_name: productName,
        price1: parseFloat(price1),
        price2: parseFloat(price2),
      })
      .then((response) => {
        // Update local state with new prices
        setPrices((prevPrices) =>
          prevPrices.map((price) =>
            price.product_name === productName
              ? { ...price, price1, price2 }
              : price
          )
        );
        // Show success alert
        window.alert("Successfully updated!");
      })
      .catch((error) => {
        console.error("Error updating price:", error);
      });
  };

  // Function to handle button click
  const handleButtonClick = (productName, price1, price2) => {
    handleUpdate(productName, price1, price2);
  };

  if (loading) return <div className="text-center p-4">Loading...</div>;

  return (
    <div className="container mx-auto p-4 py-20">
      <h1 className="text-2xl font-bold mb-4">Gold Prices</h1>
      <div className="grid gap-4 grid-cols-2 sm:grid-cols-2 md:grid-cols-3">
        {prices.map((price) => (
          <div key={price.product_name} className="bg-white border border-gray-200 rounded-lg shadow p-4">
            <h2 className="text-xl font-semibold mb-2">{price.product_name}</h2>
            <div className="mb-2">
              <label className="block text-sm font-medium mb-1">Price 1</label>
              <input
                type="number"
                value={price.price1}
                onChange={(e) =>
                  setPrices((prevPrices) =>
                    prevPrices.map((p) =>
                      p.product_name === price.product_name
                        ? { ...p, price1: e.target.value }
                        : p
                    )
                  )
                }
                className="border rounded px-2 py-1 w-full text-sm"
              />
            </div>
            <div className="mb-2">
              <label className="block text-sm font-medium mb-1">Price 2</label>
              <input
                type="number"
                value={price.price2}
                onChange={(e) =>
                  setPrices((prevPrices) =>
                    prevPrices.map((p) =>
                      p.product_name === price.product_name
                        ? { ...p, price2: e.target.value }
                        : p
                    )
                  )
                }
                className="border rounded px-2 py-1 w-full text-sm"
              />
            </div>
            <button
              onClick={() =>
                handleButtonClick(price.product_name, price.price1, price.price2)
              }
              className="bg-yellow-500 text-white px-4 py-2 rounded text-sm w-full"
            >
              Update
            </button>
          </div>
        ))}
      </div>
        {/* Navigation Links */}
        <div className="flex justify-center fixed bottom-0 w-full p-2 z-10">
            <div className="flex flex-row justify-around w-full max-w-md p-2 bg-yellow-500 mx-2 rounded-full">
              <div className="flex flex-col items-center group">
                <Link
                  to="/user/dashboard"
                  className="flex flex-col items-center px-4 rounded-full text-white"
                >
                  <RiArrowGoBackFill className="h-6 w-6 mb-1 text-white" />
                  <span className="text-[10px]">BACK</span>
                </Link>
              </div>
              <div className="flex flex-col items-center group">
                <Link
                  to="/"
                  className="flex flex-col items-center px-4 rounded-full text-white"
                >
                  <RiStore3Fill className="h-6 w-6 mb-1 text-white" />
                  <span className="text-[10px]">HOME</span>
                </Link>
              </div>
            </div>
          </div>
    </div>
  );
};

export default PriceTable;
