import React, { useEffect, useState } from "react";
import axios from "axios";
import { Admin_CRUD_SUBSCRIPTION_URL } from "../hooks/APIHooks";
import { useAuth } from "../hooks/GlobalProvider";
import { useTable, usePagination } from "react-table";

const SubscriptionList = () => {
  const { user } = useAuth();
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteError, setDeleteError] = useState(null);

  useEffect(() => {
    if (user && user.role === "admin") {
      axios
        .get(Admin_CRUD_SUBSCRIPTION_URL)
        .then((response) => {
          setSubscriptions(response.data);
          setLoading(false);
        })
        .catch(() => {
          setError("Failed to fetch subscriptions");
          setLoading(false);
        });
    } else {
      setError("Unauthorized access");
      setLoading(false);
    }
  }, [user]);

  const handleDeleteClick = (subscriptionId) => {
    if (window.confirm("Are you sure you want to delete this subscription?")) {
      axios
        .delete(`${Admin_CRUD_SUBSCRIPTION_URL}?id=${subscriptionId}`)
        .then(() => {
          setSubscriptions((prev) =>
            prev.filter((sub) => sub.id !== subscriptionId)
          );
        })
        .catch(() => {
          setDeleteError("Failed to delete the subscription.");
        });
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Users",
        accessor: "users",
        Cell: ({ row }) => (
          <div className="text-sm">
            <div>
              <p className="font-semibold">Email</p>
              <p>{row.original.email}</p>
            </div>
            <div>
              <p className="font-semibold">UPI</p>
              <p>{row.original.upi}</p>
            </div>
            <div>
              <p className="font-semibold">Recurring Type</p>
              <p>{row.original.recurring_type}</p>
            </div>
            <div
            className={` px-2 py-1 rounded text-white ${
              row.original.status === "FAILED"
                ? "bg-red-500"
                : "bg-yellow-500"
            }`}
          >
            {row.original.status}
          </div>
          </div>
        ),
      },
      {
        Header: "Transactions",
        accessor: "Transactions",
        Cell: ({ row }) => (
          <div className="text-sm">
            <div>
              <p className="font-semibold">Transaction ID</p>
              <p>{row.original.transactionId}</p>
            </div>
            <div>
              <p className="font-semibold">Subscription ID</p>
              <p className="break-all">{row.original.subscriptionId}</p>
            </div>
          </div>
        ),
      },
    
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <button
            onClick={() => handleDeleteClick(row.original.id)}
            className="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-600 transition"
          >
            X
          </button>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    page,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: subscriptions,
      initialState: { pageSize: 5 },
    },
    usePagination
  );

  if (loading) {
    return <div className="text-center text-lg">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-lg text-red-500">{error}</div>;
  }

  return (
    <div className="bg-white shadow rounded p-2">
      <h1 className="text-xl font-bold mb-4 text-center">Subscription List</h1>

      <table {...getTableProps()} className="table-auto w-full text-left">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-100">
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="py-2 px-2 font-semibold border-b"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={`border-b ${
                  row.original.status === "FAILED"
                    ? "bg-yellow-700"
                    : "bg-yellow-500"
                }`}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} className="py-2 px-2">
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="flex justify-between items-center mt-4 space-x-4">
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="bg-yellow-500 hover:bg-yellow-700 py-1 px-3 rounded disabled:opacity-50"
        >
          Previous
        </button>
        <span className="flex-grow text-center text-[12px] sm:text-base w-1/2">
          Page {pageIndex + 1} of {pageCount}
        </span>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="bg-yellow-500 hover:bg-yellow-700 py-1 px-3 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>

      {deleteError && (
        <p className="text-red-500 text-center mt-4">{deleteError}</p>
      )}
    </div>
  );
};

export default SubscriptionList;
