import React, { useState, useEffect } from "react";
import axios from "axios";
import { FetchMedia } from "../../hooks/APIHooks";

const MediaManager = () => {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFilePath, setSelectedFilePath] = useState(null); // New state to store selected file path
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const API_URL = FetchMedia; // Update with your actual endpoint

  // Fetch media files
  const fetchMediaFiles = async () => {
    setLoading(true);
    try {
      const response = await axios.get(API_URL);
      if (response.data.success) {
        setMediaFiles(response.data.images.reverse()); // Newly uploaded files come first
        setError(null);
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      setError("Failed to fetch media files.");
    } finally {
      setLoading(false);
    }
  };

  // Handle file upload
  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      setError("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("media_file", file);

    setLoading(true);
    try {
      const response = await axios.post(API_URL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.success) {
        fetchMediaFiles();
        setFile(null);
        setError(null);
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      setError("Failed to upload file.");
    } finally {
      setLoading(false);
    }
  };

  // Handle file deletion
  const handleDelete = async (fileName) => {
    if (!window.confirm("Are you sure you want to delete this file?")) return;

    setLoading(true);
    try {
      const response = await axios.delete(API_URL, {
        data: { file_name: fileName },
      });
      if (response.data.success) {
        fetchMediaFiles();
        setError(null);
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      setError("Failed to delete file.");
    } finally {
      setLoading(false);
    }
  };

  // Open modal with the file path
  const handleThumbnailClick = (filePath) => {
    setSelectedFilePath(filePath);
    setShowModal(true); // Show the modal when thumbnail is clicked
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
    setSelectedFilePath(null);
  };

  // Initial fetch
  useEffect(() => {
    fetchMediaFiles();
  }, []);

  return (
    <div className="container mx-auto p-4 max-w-5xl">
      <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">
        Media Manager
      </h1>

      {/* Upload Section */}
      <form
        onSubmit={handleUpload}
        className="flex flex-col md:flex-row items-center justify-center mb-6 gap-4"
      >
        <input
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
          className="block w-full max-w-xs px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <button
          type="submit"
          className="bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 disabled:opacity-50"
          disabled={loading}
        >
          {loading ? "Uploading..." : "Upload"}
        </button>
      </form>

      {error && <p className="text-red-500 text-center mb-4">{error}</p>}

      {/* Media Files List */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
        {mediaFiles.length > 0 ? (
          mediaFiles.map((media) => (
            <div
              key={media.id}
              className="relative border rounded-md overflow-hidden shadow-lg transition-transform transform hover:scale-105"
            >
              <img
                src={media.file_path}
                alt={media.file_name}
                className="w-full h-32 object-cover cursor-pointer"
                onClick={() => handleThumbnailClick(media.file_path)} // Open the modal on click
              />
              <div className="absolute top-2 right-2">
                <button
                  onClick={() => handleDelete(media.file_name)}
                  className="bg-red-500 text-white px-3 py-1 text-xs rounded-md hover:bg-red-600"
                >
                  Delete
                </button>
              </div>
              <div className="p-2 text-sm text-gray-700 truncate">
                {media.file_name}
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-center col-span-full mt-6">
            No media files found.
          </p>
        )}
      </div>

      {loading && <p className="text-blue-500 text-center mt-6">Loading...</p>}

      {/* Modal to display file path and full image */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
            <h2 className="text-xl font-bold mb-4">File Path</h2>

            {/* Displaying full image */}
            <div className="mb-4">
              <img
                src={selectedFilePath}
                alt="Full image"
                className="w-full h-auto max-h-96 object-contain rounded-md"
              />
            </div>

            {/* Display file path */}
            <p className="text-gray-700 mb-4">{selectedFilePath}</p>

            <button
              onClick={closeModal}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaManager;
