import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RiArrowGoBackFill, RiStore3Fill } from "react-icons/ri";
import { Admin_CRUD_PRODUCT_POST } from '../hooks/APIHooks';

function Productpost() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [makingPercentage, setMakingPercentage] = useState('');
    const [referralCommission, setReferralCommission] = useState('');
    const [productCatalogue, setProductCatalogue] = useState('Gold Coin');
    const [weight, setWeight] = useState('');
    const [image, setImage] = useState(null);
    const [products, setProducts] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const categories = [
        "Gold Coin", "Silver Coin", "Chains", "Blackbeads", "Ear Tops and Hangings",
        "Finger Rings", "Bangles", "Bracelets and Kadas", "Bajubandh", "Necklaces",
        "Haram", "Vaddanam", "Jadas", "Light weight", "Nose pin", "Diamond",
        "Bridal", "Platinum", "Ear Rings"
    ];

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await fetch(Admin_CRUD_PRODUCT_POST);
            const data = await response.json();
            setProducts(data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('making_percentage', makingPercentage);
        formData.append('referral_commission', referralCommission);
        formData.append('product_catalogue', productCatalogue);
        formData.append('weight', weight);
        formData.append('image', image);

        try {
            const response = await fetch(Admin_CRUD_PRODUCT_POST, {
                method: 'POST',
                body: formData
            });

            const data = await response.text();
            console.log(data);
            fetchProducts();
            resetForm();

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleRemove = async (id) => {
        try {
            const response = await fetch(`${Admin_CRUD_PRODUCT_POST}?id=${id}`, {
                method: 'DELETE'
            });

            const data = await response.text();
            console.log(data);
            fetchProducts();

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const resetForm = () => {
        setTitle('');
        setDescription('');
        setMakingPercentage('');
        setReferralCommission('');
        setProductCatalogue('Gold Coin');
        setWeight('');
        setImage(null);
        document.getElementById('fileInput').value = null;
    };

    const toggleCategory = (category) => {
        setSelectedCategories((prevSelected) =>
            prevSelected.includes(category)
                ? prevSelected.filter((item) => item !== category)
                : [...prevSelected, category]
        );
    };

    const filteredProducts = products.filter((product) =>
        selectedCategories.length === 0 || selectedCategories.includes(product.product_catalogue)
    );

    return (
        <section className="p-4 max-w-screen-lg mx-auto">
            <div className="mb-6">
                <h2 className="text-2xl font-bold mb-4">Upload Product</h2>
                <form onSubmit={handleSubmit} className="space-y-6 bg-white p-6 rounded-lg shadow">
                    <div>
                        <label className="font-semibold block mb-1">Title:</label>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                            className="p-2 w-full border rounded"
                        />
                    </div>
                    <div>
                        <label className="font-semibold block mb-1">Description:</label>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                            className="p-2 w-full border rounded"
                        ></textarea>
                    </div>
                    <div>
                        <label className="font-semibold block mb-1">Making Percentage:</label>
                        <input
                            type="number"
                            value={makingPercentage}
                            onChange={(e) => setMakingPercentage(e.target.value)}
                            step="0.01"
                            required
                            className="p-2 w-full border rounded"
                        />
                    </div>
                    <div>
                        <label className="font-semibold block mb-1">Referral Commission:</label>
                        <input
                            type="number"
                            value={referralCommission}
                            onChange={(e) => setReferralCommission(e.target.value)}
                            step="0.01"
                            required
                            className="p-2 w-full border rounded"
                        />
                    </div>
                    <div>
                        <label className="font-semibold block mb-1">Product Catalogue:</label>
                        <select
                            value={productCatalogue}
                            onChange={(e) => setProductCatalogue(e.target.value)}
                            required
                            className="p-2 w-full border rounded"
                        >
                            {categories.map((category) => (
                                <option key={category} value={category}>
                                    {category}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="font-semibold block mb-1">Weight (g):</label>
                        <input
                            type="number"
                            value={weight}
                            onChange={(e) => setWeight(e.target.value)}
                            step="0.01"
                            required
                            className="p-2 w-full border rounded"
                        />
                    </div>
                    <div>
                        <label className="font-semibold block mb-1">Image:</label>
                        <input
                            id="fileInput"
                            type="file"
                            accept="image/*"
                            onChange={(e) => setImage(e.target.files[0])}
                            required
                            className="p-2 w-full border rounded"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-yellow-500 text-white py-2 rounded hover:bg-yellow-600"
                    >
                        Upload Product
                    </button>
                </form>
            </div>

            <div>
                <h2 className="text-xl font-bold mb-4">Product List</h2>
                <div className="flex flex-wrap mb-6">
                    {categories.map((category) => (
                        <label key={category} className="flex items-center mr-4 mb-2">
                            <input
                                type="checkbox"
                                value={category}
                                onChange={() => toggleCategory(category)}
                                className="mr-2"
                            />
                            {category}
                        </label>
                    ))}
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
                    {filteredProducts.map((product) => (
                        <div key={product.id} className="bg-white border rounded-lg shadow p-4">
                            <h3 className="text-lg font-semibold mb-2">{product.title}</h3>
                            <p className="text-sm mb-1"><span className="font-bold">Description:</span> {product.description}</p>
                            <p className="text-sm mb-1"><span className="font-bold">Making %:</span> {product.making_percentage}%</p>
                            <p className="text-sm mb-1"><span className="font-bold">Referral %:</span> {product.referral_commission}%</p>
                            <p className="text-sm mb-1"><span className="font-bold">Catalogue:</span> {product.product_catalogue}</p>
                            <p className="text-sm mb-1"><span className="font-bold">Weight:</span> {product.weight} g</p>
                            <img
                                src={`data:image/jpeg;base64,${product.image_data}`}
                                alt={product.title}
                                className="w-full h-32 object-cover rounded mb-2"
                            />
                            <button
                                onClick={() => handleRemove(product.id)}
                                className="w-full bg-red-500 text-white py-1 rounded hover:bg-red-600"
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                </div>
            </div>
           
        </section>
    );
}

export default Productpost;
