import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/GlobalProvider";
import { Admin_CRUD_TRANSACTIONS_URL } from "../hooks/APIHooks";
import { RiArrowGoBackFill, RiStore3Fill } from "react-icons/ri";
import { Link } from "react-router-dom";

const AllTransationsHistory = () => {
  const { user } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState("All");
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(Admin_CRUD_TRANSACTIONS_URL);
        const data = await response.json();

        if (data.success) {
          setTransactions(data.transactions);
        } else {
          setError("Failed to fetch transactions");
        }
      } catch (error) {
        setError("Error fetching transactions");
      }
      setLoading(false);
    };

    fetchTransactions();
  }, []);

  const calculateGoldPriceAt = (amount, goldgrams) => {
    return ((amount / goldgrams) * 0.65).toFixed(2);
  };

  const calculateAverageGoldPriceAt = (transactions) => {
    const completedTransactions = transactions.filter(transaction => transaction.status && transaction.status.toLowerCase() === "completed");

    if (completedTransactions.length === 0) return 0;

    const totalGoldPrice = completedTransactions.reduce((sum, transaction) => sum + (transaction.amount / transaction.goldgrams), 0);
    return (totalGoldPrice / completedTransactions.length * 0.65).toFixed(2);
  };

  const calculateTotalAmountCompleted = (transactions) => {
    return transactions
      .filter(transaction => transaction.status && transaction.status.toLowerCase() === "completed" && transaction.shortName === "BAT_DigitalGold")
      .reduce((sum, transaction) => sum + parseFloat(transaction.amount), 0)
      .toFixed(2);
  };

  const filteredTransactions = transactions.filter((transaction) => {
    const transactionDateParts = (transaction.created_at ? transaction.created_at.split(" ")[0] : "").split("-");
    const formattedTransactionDate = transactionDateParts.length === 3
      ? new Date(`${transactionDateParts[2]}-${transactionDateParts[1]}-${transactionDateParts[0]}`).toISOString().split('T')[0]
      : null;

    const statusMatch = filter === "All" || (transaction.status && transaction.status.toLowerCase() === filter.toLowerCase());
    const dateMatch = !selectedDate || formattedTransactionDate === selectedDate;

    return statusMatch && dateMatch;
  });

  const completedTransactions = filteredTransactions.filter(transaction => transaction.status && transaction.status.toLowerCase() === "completed");
  const totalReceivedGold = completedTransactions.reduce((sum, transaction) => sum + parseFloat(transaction.goldgrams), 0);
  const totalAmountCompleted = calculateTotalAmountCompleted(filteredTransactions);

  const renderTable = (transactions) => (
    <div className="bg-white shadow-lg rounded-md p-2">
      <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">
        Gold Nuggets History
      </h2>

      <div className="flex justify-center mb-4">
        <button
          className={`mx-2 py-1 px-4 rounded ${filter === "All" ? "bg-yellow-500 text-white" : "bg-gray-200"}`}
          onClick={() => setFilter("All")}
        >
          All
        </button>
        <button
          className={`mx-2 py-1 px-4 rounded ${filter === "Completed" ? "bg-green-500 text-white" : "bg-gray-200"}`}
          onClick={() => setFilter("Completed")}
        >
          Completed
        </button>
        <button
          className={`mx-2 py-1 px-4 rounded ${filter === "Failed" ? "bg-red-500 text-white" : "bg-gray-200"}`}
          onClick={() => setFilter("Failed")}
        >
          Failed
        </button>
      </div>

      <div className="flex justify-center mb-4">
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          className="mx-2 py-1 px-4 rounded bg-gray-200"
        />
      </div>

      <div className="grid grid-cols-3 gap-4 text-center mb-4">
        <div className="shadow-lg p-4 rounded-md bg-yellow-500">
          <h3 className="font-semibold text-sm">Total Received Gold:</h3>
          <p className="text-xl font-bold">{totalReceivedGold} grams</p>
        </div>
        <div className="shadow-lg p-4 rounded-md bg-yellow-500">
          <h3 className="font-semibold text-sm">Total Completed Amount:</h3>
          <p className="text-xl font-bold">{totalAmountCompleted} ₹</p>
        </div>
        <div className="shadow-lg p-4 rounded-md bg-yellow-500">
          <h3 className="font-semibold text-sm">Average Purchased Price:</h3>
          <p className="text-xl font-bold">{calculateAverageGoldPriceAt(filteredTransactions)} ₹</p>
        </div>
      </div>

      {filteredTransactions.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <tbody>
              {filteredTransactions
                .filter(transaction => transaction.shortName === "BAT_DigitalGold")
                .map(transaction => (
                  <tr key={transaction.id} className="border-b">
                    <td className="grid grid-cols-2 p-4 text-black">
                      <div className="text-sm">
                        Paid: <br />
                        {transaction.amount}
                      </div>

                      <div className="text-xs font-bold">
                        {transaction.email} || {transaction.mobileNumber}
                        <br />
                        STATUS
                        <td
                          className={`text-xs ${
                            transaction.status && transaction.status.toLowerCase() === "completed"
                              ? "text-green-500"
                              : "text-red-500"
                          }`}
                        >
                          {transaction.status}
                        </td>
                      </div>

                      <div className="text-xs font-bold">
                        Transaction ID: <br />
                        {transaction.transactionId}
                        <br />
                        {(transaction.created_at)}
                      </div>
                      <div className="text-sm">
                        Received Gold: <br />
                        {transaction.goldgrams}
                        <br />
                        Purchased Gold Price at: <br />
                        {calculateGoldPriceAt(transaction.amount, transaction.goldgrams)} ₹
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-600">No gold transactions found.</p>
      )}
    </div>
  );

  return (
    <section className="p-2 bg-gray-100 min-h-screen py-20">
      {loading ? (
        <div className="flex items-center justify-center h-screen text-red-500">
          Loading...
        </div>
      ) : error ? (
        <div className="flex items-center justify-center h-screen text-red-500">
          {error}
        </div>
      ) : (
        renderTable(filteredTransactions)
      )}
      <div className="flex justify-center fixed bottom-0 w-full p-2 z-10">
        <div className="flex flex-row justify-around w-full max-w-md p-2 bg-yellow-500 mx-2 rounded-full">
          <div className="flex flex-col items-center group">
            <Link
              to="/user/dashboard"
              className="flex flex-col items-center px-4 rounded-full text-white"
            >
              <RiArrowGoBackFill className="h-6 w-6 mb-1 text-white" />
              <span className="text-xs">BACK</span>
            </Link>
          </div>
          <div className="flex flex-col items-center group">
            <Link
              to="/"
              className="flex flex-col items-center px-4 rounded-full text-white"
            >
              <RiStore3Fill className="h-6 w-6 mb-1 text-white" />
              <span className="text-xs">HOME</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllTransationsHistory;
